import styled from "styled-components";

export const RoundSpan = styled.span`
  display: inline-block;
  border: 2px solid black;
  border-radius: 20px;
  padding: 5px;
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
  text-align: center;
  font-weight: bold;
`;
