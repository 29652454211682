import React from "react";
import { LeftSideWrapper, RightSideWrapper } from "./RobotSlices.styles";
import { RichText } from "prismic-reactjs";
import { RoundSpan } from "../../styles/RoundSpan";
import { PopupText } from "react-calendly";

const RobotSlices = ({ body }) => {
  return (
    <>
      {body.map((slice, index) => {
        if (slice.primary.type === "Leftside_Image") {
          return (
            <section id={slice.primary.section} key={slice.primary.section}>
              <LeftSideWrapper>
                <img
                  src={slice.primary.slice_image.url}
                  alt={slice.primary.slice_image.alt}
                />
                <div>
                  <RoundSpan>{index}</RoundSpan>
                  <RichText render={slice.primary.text} />
                </div>
              </LeftSideWrapper>
            </section>
          );
        } else if (slice.primary.type === "Rightside_Image") {
          return (
            <section
              id={slice.primary.section}
              key={slice.primary.section}
              style={{
                background: slice.primary.background_color,
              }}
            >
              {slice.primary.title && <RichText render={slice.primary.title} />}
              <RightSideWrapper>
                <div>
                  {index > 0 && <RoundSpan>{index}</RoundSpan>}
                  <RichText render={slice.primary.text} />
                </div>

                <img
                  src={slice.primary.slice_image.url}
                  alt={slice.primary.slice_image.alt}
                />
              </RightSideWrapper>
            </section>
          );
        } else if (slice.primary.type === "Calendar_Image") {
          return (
            <section
              id={slice.primary.section}
              key={slice.primary.section}
              style={{
                background: slice.primary.background_color,
                color: "#fff",
              }}
            >
              <LeftSideWrapper>
                <img
                  src={slice.primary.slice_image.url}
                  alt={slice.primary.slice_image.alt}
                />

                <div id="calendar">
                  <RichText render={slice.primary.text} />

                  <PopupText
                    text="Varaa aika esittelylle"
                    url="https://calendly.com/timo-sahakari"
                  />
                </div>
              </LeftSideWrapper>
            </section>
          );
        }
        return null;
      })}
    </>
  );
};

export default RobotSlices;
