import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import RobotSlices from "../components/RobotSlices";

const RobotPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Robotin käyttöönotto" />
      <RobotSlices body={data.prismic.allRobotpages.edges[0].node.body} />
    </Layout>
  );
};

export default RobotPage;

export const robotpageQuery = graphql`
  query RobotPageQuery {
    prismic {
      allRobotpages {
        edges {
          node {
            _meta {
              uid
              lang
            }
            body {
              ... on PRISMIC_RobotpageBodyImage_text {
                type
                primary {
                  type
                  section
                  background_color
                  color
                  title
                  text
                  slice_image
                }
              }
            }
          }
        }
      }
    }
  }
`;
