import styled from "styled-components";

export const LeftSideWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;

  img {
    justify-self: center;
    padding: 0;
    margin-bottom: 20px;
    /*display: none;*/
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 50px;
    text-align: left;

    img {
      display: block;
      justify-self: right;
    }
  }
`;

export const RightSideWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;

  img {
    /*display: none;*/
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin: 0;
    gap: 50px;
    text-align: right;

    img {
      display: block;
    }
  }
`;
